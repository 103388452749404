


@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('../src/CSS/style.css');
@import url('../src/CSS/default.css');
@import url('../src/CSS/responsive.css');
@import url('../src/CSS/animate.min.css');
/* @import url('../src//CSS/heLanding.css'); */

.owl-carousel .owl-item img {
    /* width: auto; */
    height: 200px;

}

@layer base {
    html {
      font-family: 'Pacifico', cursive;
    }
  
    video {
      height: 200px;
    }
  }
  